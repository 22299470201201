<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="headline__container"
    :class="[
      props.containerClasses,
      { 'mx-auto': props.alignText === 'center' },
      props.width,
      props.maxWidth,
    ]"
  >
    <component
      :is="level"
      :class="styleClasses"
      :style="getHeadlineStyle()"
      class="minilu-headline max-w-[calc(100vw-var(--thm-spacing-md))]"
      :data-testid="testid"
    >
      <slot v-if="$slots.default" />
      <span v-else :data-testid="`${testid}Span`" v-html="t(headline)"></span>
    </component>
    <div
      v-if="
        hasDecoration &&
        (level === EHeadlineSize.H1 || level === EHeadlineSize.H2)
      "
      class="flex items-center justify-center h-auto mx-auto cursor-default pb-xs pt-2xs md:pt-0 md:pb-xs"
      :class="{
        'w-full md:w-[270px]': mobileFullDecoWidth,
        'w-[270px]': !mobileFullDecoWidth,
      }"
    >
      <HeadlineDeco
        :stroke-width="props.strokeWidth"
        :stroke-color="strokeColor ?? 'stroke-inherit'"
        :logo-color-main="logoColorMain"
        :logo-color-second="props.logoColorSecond"
        :container-class="
          decoContainerClasses + (mobileFullDecoWidth ? ' w-full' : '')
        "
        :mobile-full-width="mobileFullDecoWidth"
      />
    </div>
    <div
      v-else-if="hasDecoration && level === EHeadlineSize.H3"
      class="headline__stroke h-[1px] mt-2xs mx-auto mb-2xs !bg-minilu-headline-stroke"
      :class="{
        'w-full md:w-[270px]': mobileFullDecoWidth,
        'w-[270px]': !mobileFullDecoWidth,
      }"
    ></div>
    <p
      v-if="subline"
      class="max-w-screen-lg mx-auto mt-sm"
      :class="styleClasses"
    >
      {{ subline }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { EHeadlineSize } from '@/@types/headline';
import HeadlineDeco from './headline-deco.vue';

export interface Props {
  headline: string;
  subline?: string;
  hasDecoration?: boolean;
  alignText?: string;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | string | EHeadlineSize;
  strokeWidth?: number;
  strokeColor?: string;
  logoColorMain?: string;
  logoColorSecond?: string;
  textColor?: string;
  classes?: string;
  containerClasses?: string;
  decoContainerClasses?: string;
  width?: string;
  testid?: string;
  mobileFullDecoWidth?: boolean;
}

const { t } = useTrans();

const props = withDefaults(defineProps<Props>(), {
  headline: '',
  subline: null,
  hasDecoration: false,
  alignText: 'center',
  level: 'h1',
  strokeWidth: 1,
  strokeColor: '#F297C0',
  logoColorMain: '#f297c0',
  logoColorSecond: '#ea5297',
  textColor: '',
  classes: '',
  containerClasses: 'font-pero',
  decoContainerClasses: '',
  width: 'w-fit',
  maxWidth: 'max-w-screen-md',
  mobileFullDecoWidth: false,
});

let styleClasses: any[] = [];
let textColorVal = '';

if (props.textColor.startsWith('color:')) {
  styleClasses = [
    {
      [getAlignment()]: true,
      'mx-auto': props.alignText === 'center',
      [props.classes]: props.classes,
    },
  ];
} else {
  setTextColor(props.level);
  styleClasses = [
    {
      [getAlignment()]: true,
      'mx-auto': props.alignText === 'center',
      [textColorVal]: true,
      [props.classes]: props.classes,
    },
  ];
}

function setTextColor(headlineLevel: string) {
  if (props.textColor !== '' && props.textColor != undefined) {
    textColorVal = props.textColor;
  } else {
    if (headlineLevel === 'h1') {
      textColorVal = 'text-minilu-font';
    } else {
      textColorVal = 'text-inherit';
    }
  }
}

function getAlignment() {
  return 'text-' + props.alignText;
}

function getHeadlineStyle() {
  if (props.textColor.startsWith('color:')) {
    return props.textColor;
  }
}
</script>
<style lang="postcss" scoped>
.minilu-headline {
  @apply hyphens-auto;
}

h1 {
  @apply !font-pero !text-[28px] !font-bold !leading-[42px];
}

h2 {
  @apply !font-pero !text-[22px] md:!text-[28px] !leading-[42px];
}

h3 {
  @apply !font-pero !text-xl !leading-xl;
}

h4 {
  @apply !font-pero !text-[22px] !font-bold !leading-[34px];
}

h5 {
  @apply !font-text !text-md !font-bold !leading-sm;
}

h6 {
  @apply !font-text;
}
</style>
